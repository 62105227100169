import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [niche, setNiche] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setconfPassword] = useState("");
  const navigate = useNavigate();

  const SignUp = async () => {
    await axios
      .post("https://courageous-teal-bunny.cyclic.app/signup", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        niche: niche,
        password: password,
        passwordConf: confPassword,
      })
      .then((res) => {
        // console.log(">>>>>",res);
        if (res.data.Error) {
          alert("Something went wrong, check details.");
          console.log(res);
        } else {
          alert("Registration successful!");
          navigate("/");
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#070707",
          color: "white",
        }}
      >
        {" "}
        <div className="container-fluid p-5">
          <h1 className="text-center m-3">Signup</h1>
          <div
            className="container-fluid p-5"
            style={{ backgroundColor: " #131313", width: "1000px" }}
          >
            <Form>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        First Name
                      </h3>

                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                       type="text"
                        rows={1}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        Email
                      </h3>
                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                        type="email"
                        rows={1}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        Password
                      </h3>
                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                        type="password"
                        rows={1}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>

                    <div
                      className="container-fluid "
                      style={{
                        color: "white",
                      }}
                    >
                      <p>
                        {" "}
                        <input
                          type="checkbox"
                          id="topping"
                          name=""
                          value=""
                        />{" "}
                        I agree with terms and condition
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        Last Name
                      </h3>

                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                        type="text"
                        rows={1}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        Niche
                      </h3>
                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                        type="text"
                        rows={1}
                        onChange={(event) => setNiche(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="m-3">
                      <h3
                        style={{
                          color: "white",
                        }}
                      >
                        Confirm Password
                      </h3>
                      <Form.Control
                        style={{
                          width: "90%",
                          padding: "12px 20px",
                          border: "2px solid #9E9E9E",
                          backgroundColor: "#131313",
                          fontSize: "16px",
                          resize: "none",
                          color: "white",
                        }}
                        type="password"
                        rows={1}
                        onChange={(event) =>
                          setconfPassword(event.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>

            <div className="container-fluid text-center">
              <button
                style={{
                  backgroundColor: "#FFD201",
                  color: "#131313",
                  border: "#FFD201",
                  width: "250px",
                  height: "50px",
                  borderRadius: "10px",
                  boxShadow: "2px 2px 8px #131313",
                  margin: "20px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                onClick={() => SignUp()}
              >
                {" "}
                {/* <Link style={{ color: "#131313" }} to="/joblists"> */}
                signup
                {/* </Link> */}
              </button>
              <div
                className="container-fluid text-center"
                style={{
                  color: "white",
                }}
              >
                <p>
                  Already had an account?
                  <Link to="/">
                    {" "}
                    <u>Login</u>
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
