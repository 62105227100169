import React, { useEffect, useState } from "react";
import FirstPage from "../components/FirstPage";
import MainNavbar from "../components/Navbar";
import "react-responsive-datepicker/dist/index.css";
import TextForm from "../components/TextForm";
import axios from "axios";
export default function MainPage() {
  
  useEffect(() => {
    allJobList();
  }, []);
  
  const [allJob, setAllJob] = useState(); 
  const allJobList = async () => {
    await axios.get("https://courageous-teal-bunny.cyclic.app/getJobs").then((res) => {
      // console.log(res.data);
      setAllJob(res.data);
    });
  };
  const jobCompeleted = async (data) => {
    // console.log(">>>>>>>>>>>,,,,",!data.completed);
    await axios
      .put(`https://courageous-teal-bunny.cyclic.app/updateStatus/${data._id}`, {
        completed: !data.completed,
      })
      .then((res) => {
        console.log(res);
        allJobList()
        alert("Job status successfully updated!");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "#070707",
        color: "white",
        height: "100%",
      }}
    >
      <MainNavbar />
      <h1 className="text-center m-3">User Panel</h1>
      <div className="container-fluid pl-5 pb-3">
        <div className="row">
          <div className="col-md-3 col-2 pl-5">
            <b> Work Title</b>
          </div>
          <div className="col-md-6 col-6 pl-5">
            <b>Notes</b>
          </div>
          <div className="col-md-3 col-4 pl-5">
            <b>More Options</b>
          </div>
        </div>
      </div>
      {allJob?.map((data, index) => {
        return (
          <>
            <FirstPage
              onClick={() => jobCompeleted(data)}
              title={data.title}
              description={data.details}
              color={data.completed ? "#FFD201" : "gray"}
              view={`/userjobview/${data._id}`}
              edit={`/viewmore/${data._id}`}
            />
          </>
        );
      })}
    </div>
  );
}
