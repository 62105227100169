import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const Login = async () => {
    // console.log(">>>>>>>>>>",email,password);
    await axios
      .post("https://courageous-teal-bunny.cyclic.app/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.Error) {
          alert("Wrong credentials!");
        } else {
          setTimeout(() => {
            alert("Login successfully");
          }, 1000);
          if (email === "admin@gmail.com") {
            navigate("/joblists");
            localStorage.setItem("data", res);
          } else {
            navigate("/mainpage");
            localStorage.setItem("data", res);
          }
        }
      })
      .catch((error) => {
        alert("Wrong credentials!");
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#070707",
          color: "white",

          // height : '100%'
        }}
      >
        {" "}
        <div className="container-fluid p-5">
          <h1 className="text-center m-3">Login</h1>
          <div
            className="container-fluid p-5"
            style={{ backgroundColor: " #131313", width: "500px" }}
          >
            <Form style={{ textAlign: "center" }}>
              <div className="text-center">
                <Form.Group className="m-3">
                  <h3
                    style={{
                      color: "white",
                      margin: "20px",
                      paddingRight: "290px",
                    }}
                  >
                    Email
                  </h3>

                  <Form.Control
                    style={{
                      width: "90%",
                      padding: "12px 20px",
                      border: "2px solid #9E9E9E",
                      backgroundColor: "#131313",
                      fontSize: "16px",
                      resize: "none",
                      color: "white",
                      margin: " auto",
                      display: " block",
                    }}
                    type="email"
                    rows={1}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Form.Group>
              </div>
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                    margin: "20px",
                    paddingRight: "220px",
                  }}
                  
                >
                  Password
                </h3>
                <Form.Control
                  style={{
                    width: "90%",
                    padding: "12px 20px",
                    border: "2px solid #9E9E9E",
                    backgroundColor: "#131313",
                    fontSize: "16px",
                    resize: "none",
                    color: "white",
                    margin: " auto",
                    display: " block",
                  }}
                  type="password"

                  rows={1}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Group>
            </Form>
            <div
              className="container-fluid text-center"
              style={{
                color: "white",
              }}
            >
              <p>
                <u>Forgot Password?</u>
              </p>
            </div>

            <div className="container-fluid text-center">
              <button
                style={{
                  backgroundColor: "#FFD201",
                  color: "#131313",
                  border: "#FFD201",
                  width: "250px",
                  height: "50px",
                  borderRadius: "10px",
                  boxShadow: "2px 2px 8px #131313",
                  margin: "20px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                onClick={() => Login()}
              >
                {" "}
                {/* <Link
                  style={{ color: "#131313" }}
                 
                  to="/mainpage" */}
                {/* > */}
                Login
                {/* </Link> */}
              </button>
              <div
                className="container-fluid text-center"
                style={{
                  color: "white",
                }}
              >
                <p>
                  <Link style={{ color: "White" }} to="/signup">
                    {" "}
                    Create an account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
