import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import MainNavbar from "../components/Navbar";
import add from "./add.png";
import { useNavigate } from "react-router-dom";
export default function EditJob(props) {
  const { jobid } = useParams();
  const navigate = useNavigate();
  const [allJob, setAllJob] = useState();
  useEffect(() => {
    gettingJobDetails();
  }, []);

  const gettingJobDetails = async () => {
    await axios.get(`https://courageous-teal-bunny.cyclic.app/getJobById/${jobid}`).then((res) => {
      console.log(res.data);
      setAllJob(res.data);
      setJobTitle(res?.data?.title);
      setJobBody(res?.data?.details);
      setJobNotes(res?.data?.notes);
      setJobYourNotes(res?.data?.userNotes)
    });
  };
  const [jobTitle, setJobTitle] = useState();
  const [jobBody, setJobBody] = useState();
  const [jobNotes, setJobNotes] = useState();
  const [jobYourNotes, setJobYourNotes] = useState();
  const [jobImage, setJobImage] = useState("");

  const EditJob = async () => {
    await axios
      .put(`https://courageous-teal-bunny.cyclic.app/updateJobDetails/${jobid}`, {
        title: jobTitle,
        notes: jobNotes,
        images: jobImage,
        details: jobBody,
        userNotes: jobYourNotes,
      })
      .then((res) => {
        console.log(res);
        navigate("/joblists");
        alert("Job updated successfully!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const jobCompeleted = async (data) => {
    // console.log(">>>>>>>>>>>,,,,",!data.completed);
    await axios
      .put(`https://courageous-teal-bunny.cyclic.app/updateStatus/${jobid}`, {
        completed: true,
      })
      .then((res) => {
        console.log(res);
        navigate("/joblists");
        alert("Job status successfully updated!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <MainNavbar />

      <div
        style={{
          backgroundColor: "#070707",
          color: "white",
          // height : '100%'
        }}
      >
        {" "}
        <div className="container-fluid p-5">
          <h1 className="text-center m-3">View the Job</h1>
          <div
            className="container-fluid p-5"
            style={{ backgroundColor: " #131313" }}
          >
            <Form>
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                  }}
                >
                  Work Title
                </h3>
                <Form.Control
                  style={{
                    width: "100%",
                    padding: "12px 20px",

                    borderTopStyle: "hidden",
                    borderRightStyle: "hidden",
                    borderLeftStyle: "hidden",
                    borderBottomStyle: "4px solid #9E9E9E",

                    backgroundColor: "#131313",
                    fontSize: "16px",
                    resize: "none",
                    color: "white",
                  }}
                  as="textarea"
                  rows={1}
                  onChange={(event) => setJobTitle(event.target.value)}
                  value={jobTitle}
                />
              </Form.Group>
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                  }}
                >
                  Details
                </h3>
                <Form.Control
                  style={{
                    width: "100%",
                    padding: "12px 20px",
                    borderTopStyle: "hidden",
                    borderRightStyle: "hidden",
                    borderLeftStyle: "hidden",
                    borderBottomStyle: "4px solid #9E9E9E",
                    backgroundColor: "#131313",
                    fontSize: "16px",
                    resize: "none",
                    color: "white",
                  }}
                  as="textarea"
                  rows={3}
                  onChange={(event) => setJobBody(event.target.value)}
                  value={jobBody}
                />
              </Form.Group>
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                  }}
                >
                  Notes
                </h3>
                <Form.Control
                  style={{
                    width: "100%",
                    padding: "12px 20px",

                    borderTopStyle: "hidden",
                    borderRightStyle: "hidden",
                    borderLeftStyle: "hidden",
                    borderBottomStyle: "4px solid #9E9E9E",
                    borderRadius: "4px",
                    backgroundColor: "#131313",
                    fontSize: "16px",
                    resize: "none",
                    color: " white",
                  }}
                  as="textarea"
                  rows={3}
                  onChange={(event) => setJobNotes(event.target.value)}
                  value={jobNotes}
                />
              </Form.Group>
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                  }}
                >
                  Photos
                </h3>
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-md-2 col-2"
                      style={{
                        border: "2px solid #9E9E9E",
                        height: "180px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="col-md-1 col-1"></div>
                    <div
                      className="col-md-2 col-2"
                      style={{
                        border: "2px solid #9E9E9E",
                        height: "180px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="col-md-1 col-1"></div>
                    <div
                      className="col-md-2 col-2"
                      style={{
                        border: "2px solid #9E9E9E",
                        height: "180px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="col-md-1 col-1"></div>
                    <div
                      className="col-md-2 col-2"
                      style={{
                        border: "2px solid #9E9E9E",
                        height: "180px",
                        borderRadius: "10px",
                      }}
                    >
                      <img
                        style={{
                          position: "relative",
                          top: "58%",
                          left: "59%",
                          height: "80px",
                          borderRadius: "10px",
                        }}
                        src={add}
                        alt=""
                      />
                    </div>
                    <div className="col-md-1 col-1"></div>
                  </div>
                </div>
              </Form.Group> 
              <hr style={{ backgroundColor: "white" }} />
              <Form.Group className="m-3">
                <h3
                  style={{
                    color: "white",
                  }}
                >
                  Your Notes
                </h3>
                <Form.Control
                  style={{
                    width: "100%",
                    padding: "12px 20px",

                    borderTopStyle: "hidden",
                    borderRightStyle: "hidden",
                    borderLeftStyle: "hidden",
                    borderBottomStyle: "4px solid #9E9E9E",
                    borderRadius: "4px",
                    backgroundColor: "#131313",
                    fontSize: "16px",
                    resize: "none",
                    color: " white",
                  }}
                  as="textarea"
                  rows={3}
                  onChange={(event) => setJobYourNotes(event.target.value)}
                  value={jobYourNotes}
                />
              </Form.Group>
            </Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-6">
                  <button
                    style={{
                      backgroundColor: "#FFD201",
                      color: "#131313",
                      border: "#FFD201",
                      width: "250px",
                      height: "50px",
                      borderRadius: "10px",
                      boxShadow: "2px 2px 8px #131313",
                      margin: "60px 0px 0px 150px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    onClick={() => EditJob()}
                  >
                    {" "}
                    {/* <Link style={{ color: "#131313" }} rel="stylesheet" to="/"> */}
                    Add Notes
                    {/* </Link> */}
                  </button>
                </div>
                <div className="col-md-6 col-6">
                  <button
                    style={{
                      backgroundColor: "#FFD201",
                      color: "#131313",
                      border: "#FFD201",
                      width: "250px",
                      height: "50px",
                      borderRadius: "10px",
                      boxShadow: "2px 2px 8px #131313",
                      margin: "60px 0px 0px 130px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    onClick={() => jobCompeleted()}
                  >
                    {" "}
                    {/* <Link style={{ color: "#131313" }} rel="stylesheet" to="/mainpage"> */}
                    Finished Job
                    {/* </Link> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
