import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import LOGO from "./LOGO.png";
export default function MainNavbar() {
  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                style={{
                  height: "50px",
                  width: "150px",
                }}
                src={LOGO}
                alt=""
              />
            </Link>
          </Navbar.Brand>
          <Nav className="me-auto">
            {/* <Navbar.Brand href="/">Home</Navbar.Brand>
            <Navbar.Brand className="px-4" href="/viewmore">
              More
            </Navbar.Brand> */}
            {/* <Navbar.Brand href="/viewmore">Finished</Navbar.Brand> */}
            <Navbar.Brand href="/">Log out</Navbar.Brand>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}
