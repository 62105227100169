import React from "react";
import Table from "react-bootstrap/Table";
import { FaCheck, FaEdit, FaEye, FaList } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function FirstPage(props) {
  return (
    <>
      <div
        className="container-fluid pl-5 mb-3 "
        style={{
          border: "1px solid gray",
          // height: "60px",
          borderRadius: "10px",
          width: "90%",
          backgroundColor: "#131313",
          // boxShadow: "2px 2px 8px #9E9E9E"
        }}
      >
        <div className="row">
          <div
            className="col-md-3 col-2 p-3"
            style={{
              color: "white",
            }}
          >
            {props.title}
          </div>
          <div
            className="col-md-6 col-6 p-3"
            style={{
              color: "white",
            }}
          >
            {props.description}
          </div>
          <div className="col-md-3 col-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-4 p-3">
                  {" "}
                  <Link rel="stylesheet" to={props.view}>
                    <FaEye color="#FFD201" size={"25"} />
                  </Link>
                </div>
                <div className="col-md-4 col-4 p-3">
                  {" "}
                  <Link rel="stylesheet" to={props.edit}>
                    <FaEdit color="#FFD201" size={"25"} />
                  </Link>
                </div>
                <div className="col-md-4 col-4 p-3">
                  {" "}
                  <FaCheck
                    onClick={props.onClick}
                    color={props.color}
                    size={"25"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
