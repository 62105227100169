import React, { useEffect, useState } from "react";
import add1 from "./add1.png";
import FirstPage from "../components/FirstPage";
import MainNavbar from "../components/Navbar";
import "react-responsive-datepicker/dist/index.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function JobLists() {
  
  useEffect(() => {
    allJobList();
  }, []);
  
  const [allJob, setAllJob] = useState(); 
  const allJobList = async () => {
    await axios.get("https://courageous-teal-bunny.cyclic.app/getJobs").then((res) => {
      // console.log(res.data);
      setAllJob(res.data);
    });
  };
  const jobCompeleted = async (data) => {
    // console.log(">>>>>>>>>>>,,,,",!data.completed);
    await axios
      .put(`https://courageous-teal-bunny.cyclic.app/updateStatus/${data._id}`, {
        completed: !data.completed,
      })
      .then((res) => {
        console.log(res);
        allJobList()
        alert("Job status successfully updated!");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "#070707",
        color: "white",
        height:"100%"
      }}
    >
      <MainNavbar />
      <div className="container-fluid mx-2 my-4">
        <div className="row">
          <div className="col-md-7">
            <h1
              style={{
                textAlign: "right",
              }}
            >
              Admin Panel
            </h1>
          </div>
          <div className="col-md-5 d-block">
            {" "}
            <Link style={{ color: "#131313" }} to="/addjob">
              <img
                style={{
                  position: "relative",
                  left: "76%",
                  height: "30px",
                  borderRadius: "10px",
                  display: "block",
                }}
                src={add1}
                alt=""
              />{" "}
              <p
                style={{
                  position: "relative",
                  left: "74.5%",
                  display: "block",
                  color: "white",
                }}
              >
                Add Job
              </p>{" "}
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid pl-5 pb-3">
        <div className="row">
          <div className="col-md-3 col-2 pl-5">
            <b> Work Title</b>
          </div>
          <div className="col-md-6 col-6 pl-5">
            <b>Notes</b>
          </div>
          <div className="col-md-3 col-4 pl-5">
            <b>More Options</b>
          </div>
        </div>
      </div>
      {allJob?.map((data, id) => {
        return (
          <>
            <FirstPage
                  onClick={() => jobCompeleted(data)}
              title={data.title}
              description={data.details}
              color={data.completed ? "#FFD201" : "gray"}
              view={`/adminjobview/${data._id}`}
              edit={`/editjob/${data._id}`}
            />
          </>
        );
      })}
      {/* <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      />
      <FirstPage
        title="Mobeen"
        description="Run this command to run the React application my-react-app : "
      /> */}
    </div>
  );
}
