import logo from "./logo.svg";
import "./App.css";
import { DatePicker } from "react-responsive-datepicker";
import "react-responsive-datepicker/dist/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import SecondPage from "./Pages/SecondPage";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";

import JobLists from "./AdminPages/JobLists";
import EditJob from "./AdminPages/EditJob";
import Addjobs from "./AdminPages/Addjobs";
import Userjobview from "./Pages/Userjobview";
import Adminjobview from "./AdminPages/Adminjobview";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/addjob" element={<Addjobs />} />
        <Route path="/mainpage" element={<MainPage />} />
        <Route path="/editjob/:jobid" element={<EditJob />} />
        <Route path="/joblists" element={<JobLists />} />
        <Route path="/viewmore/:jobid" element={<SecondPage />} />
        <Route path="/userjobview/:jobid" element={<Userjobview />} />
        <Route path="/adminjobview/:jobid" element={<Adminjobview />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
